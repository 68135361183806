import React from "react";
import styled from "styled-components";
import AltImage from "../AltImage";
import Text from "../editorial/Text";
import Spacer from "./Spacer";

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const Title = styled.div`
  background-color: ${({ theme }) => theme.colors.titanWhite};
`;

const ImageContainer = styled.div`
	position: relative;
	width: 100%;
	/* background-color: palegoldenrod; */
	display: flex;
	align-items: center;
	justify-content: center;
`;

const TextContainer = styled.div`
	position: absolute;
	z-index: 1;
	padding-left: ${p => p.theme.margins.medium.l};
	padding-right: ${p => p.theme.margins.medium.l};
	text-align: left;
	max-width: ${({ theme }) => theme.sizes.maxWidth};

	@media (max-width: ${({ theme }) => theme.breakPoints.m}) {
		padding-left: ${p => p.theme.margins.medium.s};
		padding-right: ${p => p.theme.margins.medium.s};
	}
`;

const SplashText = styled(Text)`
	width: 50%;

	@media (max-width: ${({ theme }) => theme.breakPoints.m}) {
		width: 100%;
	}
`

const SplashImage = styled(AltImage)`
  min-height: 10rem;
  object-fit: cover;
  width: 100%;
`;

const PageHeader = (props) => {
	return (
		<Comp>
			<Title m="small">
				<Spacer s="medium" />

				<Text s="h1" as="h1" c="main01">
					{props.title}
				</Text>
				<Spacer s="medium" />
			</Title>

			<ImageContainer>
				{props.text ?
					<TextContainer>
						<SplashText s="h1" c="main01">
							{props.text}
						</SplashText>
					</TextContainer>

					: null}
				{props.image ? <SplashImage data={props.image} /> : null}
			</ImageContainer>
			<Spacer s="medium" />
		</Comp>
	);
};

export default PageHeader;

import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/_global/layout/Layout";
import localize from "../components/_global/localization/localize";
import Section from "../components/_global/layout/Section";
import AltImage from "../components/_global/AltImage";
import Text from "../components/_global/editorial/Text";
import Spacer from "../components/_global/layout/Spacer";
import Block from "../components/_global/editorial/Block";
import RoundButton from "../components/_global/RoundButton";
import { useLanguageContext } from "../contexts/LanguageContext";
import PageHeader from "../components/_global/layout/PageHeader";

const HeaderImage = styled(AltImage)`
  max-height: 30rem;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top center;
`;

const DateCont = styled(Text)`
  align-self: center;
`;

const HorizLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.main01};
`;

const Photo = ({ data, ...props }) => {
    const { file, gallery } = data;

    const photos = file.images.map((item, index) => {
        return (
            <React.Fragment>
                <AltImage key={index} data={item} />
                <Spacer s="medium" />
            </React.Fragment>
        )
    })

    return (
        <Layout title={gallery.common.title}>
            <PageHeader title={gallery.common.title} />
            <Section m="medium">
                <Text s="medium">
                    {file.description}
                </Text>
                <Spacer s="large" />
                {photos}
                <Spacer s="small" />

                <RoundButton data={{
                    title: gallery.backButton,
                    href: 'gallery'
                }} type="pill" style={{ alignSelf: "center" }} />
            </Section>
            <Spacer s="large" />
            <Spacer s="large" />
        </Layout>
    );
};

export default localize(Photo);

export const query = graphql`
    fragment PhotoFragment on SanityPhoto {
        _id
      description {
        _type
        fr
      }
      date
      images {
        ...ImageFragment
      }
    }

    query($_id: String!) {
        gallery: sanityGallery {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            backButton {
              _type
              fr
            }
        }
        file: sanityPhoto( _id: { eq: $_id } ) {
            ...PhotoFragment
        }
    }
`;
